// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/Users/danbrewster/projects/bowie-final-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-index-js": () => import("/Users/danbrewster/projects/bowie-final-site/src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ja-narration-js": () => import("/Users/danbrewster/projects/bowie-final-site/src/pages/ja/narration.js" /* webpackChunkName: "component---src-pages-ja-narration-js" */),
  "component---src-pages-landing-js": () => import("/Users/danbrewster/projects/bowie-final-site/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/danbrewster/projects/bowie-final-site/.cache/data.json")

